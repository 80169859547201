<template>
  <!-- My 포인트 -->
  <coach-my-page-content-layout user-point>
    <my-page-title>
      <template #left>
        <span>MY 포인트</span>
        <point-icon shape="white" :value="upBalancePoint" />
      </template>
      <template #right>
        <color-button type="purple" @click="onRecharge" class="btn-recharge">충전하기</color-button>
        <point-icon shape="white" :value="upBalancePoint" class="mobile" @click="onRecharge" clickable />
      </template>
    </my-page-title>
    <div class="table-holder" :class="{'scroll-end': scrollEnd}" v-if="hasContent">
      <CoTable rowKey="cashTransactionId" :value="transactionItems" @sort="onSort">
        <TableColumn key="a" field="transactionType" title="구분" align="center" :width="100" :render="renderType" :sort="sortOptions.transactionType || ''" />
        <TableColumn key="b" field="text" title="내용" :width="200" />
        <TableColumn key="c" field="amount" title="포인트 증/감" align="center" :width="100" :render="renderAmount" />
        <TableColumn key="d" field="remainBalance" title="잔여 포인트" align="center" :width="117" />
        <TableColumn key="e" field="createdDatetime" title="날짜" align="center" :width="96" :render="renderDate" :sort="sortOptions.createdDatetime || ''" />
      </CoTable>
    </div>
    <no-content v-else text="아직 포인트 내역이 없습니다" alt="eyes" src="/img/coaching/seeking-eyes.svg" />
    <div class="area-bottom">
      <Pagination v-if="!loading && hasContent" :chunk-size="listSize" :info="transactionInfo" theme="coaching" @change="changeCursor" />
      <Selector v-if="hasContent" :list="listSizes" :value="listSize" class="selector-listsize" theme="white-reverse" @change="onListSizeChange" />
    </div>
    <color-button type="purple" @click="onRecharge" class="btn-recharge-mobile">충전하기</color-button>
  </coach-my-page-content-layout>
</template>

<script>
import cursorFetcher from 'shared/mixins/cursorFetcher';
import ColorButton from '@shared/components/common/ColorButton.vue';
import { getShortYMD } from 'shared/utils/timeUtils';
import CoachMyPageContentLayout from '@/views/layout/CoachMyPageContentLayout.vue';
import MyPageTitle from '@/views/components/coaching/coach-my-page/MyPageTitle.vue';
import PointIcon from '@/views/components/coaching/PointIcon.vue';
import TableColumn from '@/views/components/coaching/sales/TableColumn.vue';
import TransactionTypeBadge from '@/views/components/coaching/coach-my-page/TransactionTypeBadge.vue';
import Pagination from '@/views/components/common/Pagination.vue';
import Selector from '@/views/components/coaching/Selector.vue';
import NoContent from '@/views/components/coaching/question-detail/NoContent.vue';
import PointBuyModal from '@/views/components/coaching/PointBuyModal.vue';
import CoTable from '@/views/components/coaching/sales/CoTable.vue';

export default {
  name: 'CoachUserPoint',
  components: { NoContent, Selector, Pagination, TableColumn, PointIcon, MyPageTitle, CoachMyPageContentLayout, ColorButton, CoTable },
  lexicon: 'coaching.settlementHistory',
  metaInfo() {
    return {
      title: this.$t('meta.myPoint.title'),
      meta: [
        { vmid: 'title', content: this.$t('meta.myPoint.title') },
        { vmid: 'url', content: this.$t('meta.myPoint.url') },
        { vmid: 'description', content: this.$t('meta.description') },
        { vmid: 'keywords', content: this.$t('meta.keywords') },
        { vmid: 'site_name', content: this.$t('meta.siteName') },
        { vmid: 'type', content: this.$t('meta.type') },
        { vmid: 'image', content: this.$t('meta.image') },
      ],
    };
  },
  data: () => ({
    transactionInfo: /** @type {PagedTransactions} */ {},
    loading: false,
    scrollEnd: false,
  }),
  mixins: [cursorFetcher({
    transactionInfo: async ({ services, size, page, sort, sortBy }) => {
      const order = {};
      if (sortBy !== '' && sort !== '') order.order = [sortBy, sort].join(' ');
      if (order?.order?.includes('transactionType')) {
        order.order += ', createdDatetime desc';
      }
      /**
       * @type {PagedTransactions}
       */
      const result = await services.coaching.getTransactions({ cursor: page, size, ...order });
      return result;
    },
  })],
  computed: {
    upBalancePoint() {
      return this.$store.state?.auth?.myInfo?.wallet?.upBalanceAmount ?? 0;
    },
    transactionItems() {
      return this.transactionInfo?.items ?? [];
    },
    hasContent() {
      return this.transactionInfo?.totalCount >= 1;
    },
    listSize: {
      get() {
        return this.$route?.query?.size ? Math.round(this.$route?.query?.size) : 10;
      },
      set(size) {
        this.$router.push({ query: this.getMergedQuery({ size, page: 0 }) });
      },
    },
    listSizes() {
      return [100, 50, 25, 10].map(value => ({ value, label: this.$t('_.listSize', [value]) }));
    },
    page: {
      get() {
        return Math.round(this.$route.query?.page ?? 0);
      },
      async set(page) {
        this.$router.push({ query: this.getMergedQuery({ page }) });
        this.transactionInfo.cursor = page;
      },
    },
    sortOptions: {
      get() {
        if (this.$route?.query?.sortBy && this.$route?.query?.sort) return { [this.$route.query.sortBy]: this.$route.query.sort };
        return {};
      },
      set(v) {
        if (Object.entries(v)?.length === 0) this.$router.push({ query: this.getRemovedQuery(['sortBy', 'sort']) });
        const [key, value] = Object.entries(v).filter(arg => !!arg[1])?.[0] ?? [];
        this.$router.push({ query: this.getMergedQuery({ sortBy: key, sort: value }) });
      },
    },
  },
  watch: {
    upBalancePoint() {
      this.resetFetch(this.transactionInfo);
    },
  },
  methods: {
    renderAmount({ row }, h) {
      let strAmount = '0';
      if (row.amount !== 0) strAmount = row.transactionType === 'WITHDRAWAL' ? `-${row.amount}` : `+${row.amount}`;
      return h('span', strAmount);
    },
    renderDate({ row }, h) {
      return h('span', getShortYMD(row.createdDatetime));
    },
    renderType({ row }, h) {
      return h(TransactionTypeBadge, { props: { value: row.transactionType } });
    },
    onListSizeChange(arg) {
      if (typeof arg === 'number') this.listSize = arg;
    },
    changeCursor(cursor) {
      this.page = cursor;
    },
    onRecharge() {
      // this.$modal(PointBuyModal);
      alert('포인트 충전이 불가합니다.');
    },
    onSort(params) {
      this.sortOptions = params;
    },
    // 스크롤 남아있을 때 그라디언트 효과 표시 여부
    onObserveScroll(entries) {
      this.scrollEnd = entries?.[0]?.isIntersecting;
    },
  },
  async asyncData({ mixinFetcher, services, route, store }) {
    const page = route.query.page ?? 0;
    const size = route.query.size ?? 10;
    const sort = route.query.sort ?? '';
    const sortBy = route.query.sortBy ?? '';
    return { ...await mixinFetcher.fetch({ store, route, services, defaultParams: { page, size, sort, sortBy } }) };
  },
  mounted() {
    this.$nextTick(() => {
      // vue table을 이용해 생성되는 엘리먼트이므로 내부에 ref로 접근할 수 없어서 임시로 query로 접근
      // 가능하면 ref로 접근할 것
      this.$veTableLast = document.querySelector('.ve-table-last-column');
      if (this.$veTableLast) {
        this.$pageScrollObserver = new IntersectionObserver(this.onObserveScroll.bind(this), { threshold: 1 });
        this.$pageScrollObserver.observe(this.$veTableLast);
      }
    });
  },
  beforeDestroy() {
    if (this.$pageScrollObserver) this.$pageScrollObserver.disconnect();
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[user-point] {
  [point-icon] {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  [status-badge] {.w(59) !important;display: inline-block;}
  .area-bottom {.mt(20);.rel;
    [selector] {.abs;.r(0);.t(0)}
  }
  [custom-table] {.mt(50);overflow-x: scroll;
    .ve-table-container {.min-w(680)}
  }
  .ve-table-header-th:nth-of-type(2) { .min-w(200);}
  .btn-recharge {.wh(260, 60);.br(50);.fs(20)}
  .btn-recharge-mobile {display: none;}
  [point-icon].mobile {display: none;}
  .ve-table-header-th:nth-child(1) {.w(15%)}
  @media (@tl-down) {
    .btn-recharge { .hide}
    [point-icon] {.hide}
    .area-bottom [selector] {.w(130);.ml(20);.mr(20)}
    [pagination] {.mb(43);.pt(65)}
    .btn-recharge-mobile {display: block;position: fixed;.w(100%);border-radius: 0;.b(0);.z(10)}
    [point-icon].mobile {display: inline-flex;
    }

  }
  // 테이블에 스크롤이 생기는 시점
  @media (max-width: 662px) {
    .table-holder {.rel;
      &::after {.abs;content: '';.wh(20, 100%);.t(0);.r(0);
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.7287289915966386) 50%, rgba(255, 255, 255, 1) 100%);
      }
    }
    .table-holder.scroll-end::after {
      display: none;
    }
  }
  @media (@mypage-desktop-up) {
    [point-icon] {.hide}
  }
  .ve-table-header-th:nth-child(1), .ve-table-header-th:nth-child(5) {
    display: inline-flex;.h(43);flex-direction: row-reverse;justify-content: center;align-items: center;.w(100%)
  }
  .ve-table-sort { .ml(-9);.mr(8)}

}
</style>
